










































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { downloadJSON } from '@/utils/fileUtils.ts'
import { vxm } from '@/store'

@Component
export default class StockValuesReport extends Vue {
  public $refs: Vue['$refs'] & {
    locationFilterForm: {
      validate: any
      reset: any
    }
  }

  private filters = {
    locationId: null as string | null,
    fromDate: null as string | null,
    toDate: null as string | null,
  }

  private locations = []

  private fromDateMenu = false
  private toDateMenu = false
  private exportJsonLoading = false
  private stockValuesData = null

  private tableHeaders = [
    { text: this.$t('c:stock-details:ID'), value: 'id' },
    { text: this.$t('c:stock-details:Site ID'), value: 'siteId' },
    { text: this.$t('c:stock-details:Location'), value: 'location' },
    { text: this.$t('c:stock-details:Date'), value: 'date' },
    { text: this.$t('c:stock-details:Type'), value: 'type' },
    { text: this.$t('c:stock-details:Quantity'), value: 'quantity' },
    { text: this.$t('c:stock-details:Inventory ID'), value: 'inventoryId' },
    { text: this.$t('c:stock-details:Inventory name'), value: 'inventoryName' },
    { text: this.$t('c:stock-details:Sum amount different list'), value: 'sumAmountDifferentList' },
    { text: this.$t('c:stock-details:Sum cost'), value: 'sumCost' },
    { text: this.$t('c:stock-details:Sum fee'), value: 'sumFee' },
    { text: this.$t('c:stock-details:Sum total'), value: 'sumTotal' },
  ]

  created() {
    this.$axios.get('/v4/site/locations').then((response) => {
      this.locations = response.data.data
    })
  }

  private executeReport() {
    if (this.$refs.locationFilterForm.validate()) {
      this.$axios
        .get('/v4/site/stock/values', { params: this.filters })
        .then((response) => {
          this.stockValuesData = response.data.data
        })
        .catch((error) => {
          console.error('Error fetching report:', error)
        })
    } else {
      vxm.alert.error(this.$t('c:stock-details:Please fill the required filters'))
    }
  }

  private clear() {
    this.filters = {
      locationId: null,
      fromDate: null,
      toDate: null,
    }
    this.fromDateMenu = false
    this.toDateMenu = false
    this.stockValuesData = null
  }

  private handleJsonDownload() {
    downloadJSON(this.stockValuesData, 'stock_values', this.setExportJsonLoading)
  }

  private setExportJsonLoading(loading: boolean): void {
    this.exportJsonLoading = loading
  }
}
